<template>
  <div>
    <div
      v-if="!isLoading"
      style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100vh;">
      <h2>{{ status == success ? $t('user.successfulActivation') : $t('user.failedActivation') }}</h2>
      <h3 v-if="status == success">{{ $t('user.redirectLoginPage', {'0': countdownInterval}) }}</h3>
    </div>
    <!-- <loading-bar :is-loading="isLoading" /> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
// import ToastType from 'Enum/toastType'
export default {
  data: () => ({
    message: '',
    redirectLoginText: '',
    success: 0,
    error: 1,
    status: null,
    isLoading: false,
    countdownInterval: null
  }),
  watch: {
    countdownInterval: {
      handler() {
        if (this.countdownInterval){
          if (this.countdownInterval > 0) {
            setTimeout(() => {
              this.countdownInterval--;
            }, 1000);
          }
        } if (this.countdownInterval === 0) {
          this.onRedirectToLogin()
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    }
  },
  created () {
    let params = this.$route.params
    let filter = {
      uid: params.uid,
      token: params.token
    }
    this.isLoading = true
    this.ACTIVATE_ACCOUNT(filter).then(
      function () {
        this.isLoading = false
        this.status = this.success
        this.countdownInterval = 5
      }.bind(this)
    ).catch(
      function () {
        this.isLoading = false
        this.status = this.error
      }.bind(this)
    )
  },
  methods: {
    /**
     * Redirect to login
     */
    onRedirectToLogin: function () {
      this.$router.push({
        path: '/login'
      })
    },
    ...mapActions(['ACTIVATE_ACCOUNT'])
  }
}
</script>

<style>

</style>
